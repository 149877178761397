import { SelectItemType } from "@evvve/ui-kit/dist/components/Select/SelectItemType";

export interface DefaultResponse {
  status: boolean;
}

export interface UserShort {
  unique_id: string;
  username: string;
  first_name: string;
  last_name: string;
  avatar_url: string;
  is_online?: boolean;
  is_deleted?: boolean;
  company?: string;
}

export interface EventMember {
  user: UserShort;
  roles: EventMemberRoleAndStatus[];
}

export enum EventMemberRole {
  ORGANIZER = "organizer",
  SPEAKER = "speaker",
  ATTENDEE = "attendee",
}
export enum EventMemberRoleWsChannel {
  ORGANIZER = "organizers",
  SPEAKER = "speakers",
  ATTENDEE = "attendees",
}

export interface EventMemberRoleAndStatus {
  role: EventMemberRole;
  status: EventMemberStatus;
}

export enum EventType {
  SIMPLE = "simple",
  COMPLEX = "complex",
}

export enum TariffType {
  FREE = "free",
  PRO = "pro",
}

export interface EventPayload extends AdditionalEventSettings {
  type: EventType;
  unique_id?: string;
  name?: string;
  description?: string;
  start_datetime?: string;
  end_datetime?: string;
  date?: string;
  cover_image_key?: string | null;
  category?: string;
  status: string;
  attendee_limit: number;
  is_public: boolean;
  tariff: TariffType;
}

export interface EventPriceResponse {
  price: number | null; // null when one of the cost parameters is missing
}

// get event by id response
export interface EventResponse extends AdditionalEventSettings {
  unique_id: string;
  type: EventType;
  name?: string;
  description?: string;
  creator: UserShort;
  start_datetime?: string;
  end_datetime?: string;
  date?: string;
  tz: string;
  cover_image_key?: string;
  cover_image_url?: string;
  category?: string;
  status: EventStatus;
  attendee_limit: number;
  is_public: boolean;
  current_session_id?: string;
  current_speaker_id?: string;
  tariff: TariffType;
  analytics: EventAnalyticsInit;
}

export interface AdditionalEventSettings {
  notify_when_has_changes?: NotificationGroup[];
  welcome_message?: string;
}

// custom event interface
export interface EventFormattedResponse extends EventResponse {
  date?: string;
  sessions?: SessionResponse[];
  speakers?: SpeakerResponse[];
  is_sold_out?: boolean;
  is_favorite?: boolean;
  roles?: EventMemberRole[];
  is_registered?: boolean;
  current_user_as_member?: EventMember | null;
  isComplexEvent?: boolean;
  analytics: EventAnalyticsInit;
}

export interface MyEventResponseModel extends EventResponse {
  // logged in user
  current_user_as_member: EventMember | null;
  // requested user
  requesting_user_as_member: EventMember;
  sessions: SessionResponse[];
}

// TODO: backed backlog task to change:
// current_user_as_member -> requesting_user_as_member
// requesting_user_as_member -> requested_user_as_member
//
// Проблема: не понятно что занчит слово current в current_user_as_memeber,
//  сurrent это юзер ивенты которого мы запращиваем или юзер под которым пользователь залогинился
//
// Слово requesing в данной имплеентации вообще не верно выбрано requesting_user_as_member,
//  сейчас это не запрашивающий юзер а запрашиваемый

// invitation request
export interface EventInvitationRequest {
  eventId: string;
  user_id?: string;
  invitation_email?: string;
}
export interface RemoveSpeakerRequest {
  eventId: string;
  user_id?: string;
  invitation_id?: string;
}

// NOTE: deprecated
export interface SpeakerResponse {
  member?: EventMember;
  invitation?: EmailInvitationResponse;
}

export type InviteSpeakerResp = {
  event_session: SessionResponse;
  member?: EventMember;
  invitation: Nullable<EmailInvitationResponse>;
};

export type InviteSpeakerQuery = {
  eventId: string;
  session_id: string;
  invitation_email?: string;
  user_id?: string;
};

export interface SpeakersResponse {
  members: EventMember[];
  invitations: EmailInvitationResponse[];
}

export interface EmailInvitationResponse {
  unique_id: string;
  email: string;
  status: EventMemberStatus;
  last_notification_at: Date;
  is_can_resend: boolean;
}

export enum EventMemberStatus {
  PENDING = "pending",
  ACCEPTED = "accepted",
  DECLINED = "declined",
}

export type Nullable<T> = T | null;

// TODO:remove
// eslint-disable-next-line @typescript-eslint/naming-convention
export interface SessionResponse_deprecated {
  unique_id: string;
  speaker_id: string;
  is_draft: boolean;
  start_datetime: Nullable<string>;
  end_datetime: Nullable<string>;
  real_start_datetime: Nullable<string>;
  real_end_datetime: Nullable<string>;
  name: Nullable<string>;
  description: Nullable<string>;
  was_started: boolean;

  invitation?: EmailInvitationResponse;
  event_member: null;
  sequence_number?: number;
}

export type SessionResponse = {
  unique_id: string;
  speaker?: UserShort;
  invitation?: EmailInvitationResponse;
  event_member?: EventMember;
  name?: string;
  description?: string;
  start_datetime?: string;
  end_datetime?: string;
  real_start_datetime?: string;
  real_end_datetime?: string;
  is_draft: boolean;
  was_started: boolean;

  sequence_number?: number;
};

export interface SessionPayload {
  unique_id?: string; // NOTE: для кейса когда апдейтим

  event_id: string;
  name: Nullable<string>;
  description: Nullable<string>;
  start_datetime: Nullable<string>;
  end_datetime: Nullable<string>;
  speaker_id: Nullable<string>;
  // NOTE: эти два значения необходимо временно передавать на BE
  // пока функционал создания сессии на BE не будет отрефакторен
  invitation_id: null;
  event_member_id: null;
}

export enum EventCategory {
  CULTURE = "Culture",
  SCIENCE = "Science",
  EDUCATION = "Education",
  BUSINESS = "Business",
  SOCIETY = "Society",
  SPORT = "Sport",
  LIFESTYLE = "Lifestyle",
  OTHER = "Other",
}

export enum EventRegistrationType {
  PUBLIC = "Public",
  PRIVATE = "Private",
}

export enum NotificationGroup {
  SPEAKERS = "speaker",
  ATTENDEES = "attendee",
}

export enum EventStatus {
  DRAFT = "draft",
  UPCOMING = "upcoming",
  PRE_LIVE = "pre_live",
  LIVE = "live",
  PAST = "past",
}

export type EventSpeakerSelectItem =
  | (SelectItemType & {
      data?: {
        username?: string;
        avatar?: string;
      };
    })
  | any;

export type SessionSelectItemType = SelectItemType & {
  data?: {
    sequenceNumber: string;
    title: string;
  };
};

export type GetEventMembersByIdItem = {
  user: UserShort;
  roles: { role: EventMemberRole; status: EventMemberStatus }[];
  is_confirmed: boolean;
  is_attended: true;
};

export interface EventInitResponse {
  event: EventResponse;
  speakers: SpeakerResponse[];
  sessions: SessionResponse[];
  current_user_as_member?: EventMember;
  is_favorite: boolean;
  is_sold_out: boolean;
  analytics: EventAnalyticsInit;
}

export interface EventInitResponseRaw {
  event: EventResponse;
  speakers: SpeakersResponse;
  sessions: SessionResponse[];
  current_user_as_member?: EventMember;
  is_favorite: boolean;
  is_sold_out: boolean;
  analytics: EventAnalyticsInit;
}

export type JoinEventResponse = {
  status: boolean;
};

export type MemberConfirmResponse = EventMember;

export enum EventCatalogSorting {
  LatestEventDate = "-start_datetime",
  EarliestEventDate = "start_datetime",
  EarlyCreatedEventAt = "dt_create",
  LastCreatedEventAt = "-dt_create",
  MostPopular = "popular",
  LessPopular = "-popular",
}

export type EventAnalyticsResponse = {
  landing_page_views: number;
  avg_attendance_time: number;
  registered: number;
  attended: number;
  attended_percent: number;
  attended_at_least_half_even: number;
  attended_at_least_half_even_percent: number;
  messages: number;
};

export type EventAnalyticsInit = {
  attendee_limit: number;
  attendee_count: number;
};

type AnalyticsEvent = {
  unique_id: string;
  date: string;
  name: string;
  status: EventStatus;
  attendees_registered: number;
  attendees_attended: number;
};

export type OrganizedEventsAnalyticsResponse = {
  events: AnalyticsEvent[];
};

export type MyOrganizedEventsAnalyticsArg = {
  page: number;
  size: number;
};
