import React from "react";

import { Avatar, Icons, Text } from "@evvve/ui-kit";
import Link from "src/shared-features/Link";
import { changeDateFormat, changeTimeFormat } from "src/helpers/date";
import { Moment } from "moment";
import styles from "./style.module.scss";

interface SystemMessageTemplateProps {
  title?: string;
  event?: SystemMessageEvent;
  dateTime?: SystemMessageDateTime;
  newDateTime?: SystemMessageDateTime;
  user?: SystemMessageUser;
  position?: "right" | "left";
}

interface SystemMessageEvent {
  title: string;
  link?: string;
}

interface SystemMessageUser {
  name: string;
  avatar_url: string;
  role?: string;
}

interface SystemMessageDateTime {
  date?: string | Date | Moment;
  startTime?: string | Date | Moment;
  endTime?: string | Date | Moment;
}

const positionStyle = {
  left: styles.positionLeft,
  right: styles.positionRight,
};

const NotificationTemplate = ({
  children,
  title,
  event,
  dateTime,
  newDateTime,
  user,
  position = "left",
}: React.PropsWithChildren<SystemMessageTemplateProps>) => {
  const hasContent = !!(event || dateTime || user || children);

  return (
    <div className={positionStyle[position]}>
      <div className={styles.wrap}>
        <div className={styles.title}>
          <div className={styles.titleIcon}>
            <Icons.Info color="tangerine1000" size="s" />
          </div>
          <Text size="m" color="tangerine1000">
            {title}
          </Text>
        </div>
        {hasContent && (
          <div className={styles.content}>
            {event && event.link && (
              <Link
                className={styles.event}
                size="m"
                color="violet"
                to={event.link}
                underline={false}
                hoverUnderLine={false}
              >
                {event.title}
              </Link>
            )}
            {event && !event.link && (
              <Text className={styles.event} size="m" color="grayscale900">
                {event.title}
              </Text>
            )}
            {children && (
              <Text size="xs" color="grayscale900" as="div">
                {children}
              </Text>
            )}
            {dateTime && (
              <div>
                <Text size="xs" color="grayscale800" as="span" fontWeight={600}>
                  Date:&nbsp;
                </Text>
                <Text size="xs" color="grayscale800" as="span">
                  {dateTime.date && changeDateFormat(dateTime.date)}&nbsp;
                  {dateTime.startTime && changeTimeFormat(dateTime.startTime)}
                  {dateTime.endTime && ` – ${changeTimeFormat(dateTime.endTime)}`}
                </Text>
              </div>
            )}

            {newDateTime && (
              <div className={styles.newDateTime}>
                {newDateTime && newDateTime.date && (
                  <div>
                    <Text size="xs" color="grayscale800" as="span">
                      New Date {changeDateFormat(newDateTime.date)}
                    </Text>
                  </div>
                )}
                {newDateTime && newDateTime.startTime && newDateTime.endTime && (
                  <div>
                    <Text size="xs" color="grayscale800" as="span">
                      New time {changeTimeFormat(newDateTime.startTime)}-
                      {changeTimeFormat(newDateTime.endTime)}
                    </Text>
                  </div>
                )}
              </div>
            )}

            {user && (
              <div className={styles.user}>
                <Avatar size="s" src={user.avatar_url} status="offline" alt="" />
                <div>
                  <Text size="m">{user.name}</Text>
                  {user.role && (
                    <Text className={styles.userRole} size="xs" color="grayscale600">
                      {user.role}
                    </Text>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationTemplate;
